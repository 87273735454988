import React from 'react'
import {createRoot} from 'react-dom/client'
import {
  BrowserRouter as Router,
} from 'react-router-dom'

import './style.css'
import Routes from './routes'



const App = () => {
  return (
    <Router>
      <Routes/>
    </Router>
  )
}

createRoot(document.getElementById('app')).render(<App />)