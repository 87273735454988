import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Wrapper from '../components/page-wrapper'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './bathrooms.css'

const Bathrooms = (props) => {
  return (
    <Wrapper>
    <div className="bathrooms-container1">
      <Helmet>
        <title>Bathrooms - Craft & Forge - Premium Home Remodeling</title>
        <meta
          name="description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:title"
          content="Bathrooms - Craft &amp; Forge - Premium Home Remodeling"
        />
        <meta
          property="og:description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/133b66a8-437a-4fe1-8938-2c01fd5c558d/c7ed6f96-1e17-4876-a525-ef1747a6b890?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar imageSrc1="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"></Navbar>
      <div className="bathrooms-hero-fade-single">
        <div className="bathrooms-hero">
          <div className="bathrooms-content-container">
            <animate-on-reveal
              animation="fadeInUp"
              duration="1000ms"
              delay="0s"
              direction="normal"
              easing="ease-out"
              iteration="1"
            >
              <div
                data-thq-animate-on-reveal="true"
                className="bathrooms-max-width1"
              >
                <div className="bathrooms-heading-container1">
                  <span className="bathrooms-prehead1 preHead">bathrooms</span>
                  <span className="bathrooms-main-title title">
                    <span>where style </span>
                    <br></br>
                    <span>meets serenity</span>
                    <br></br>
                  </span>
                  <span className="bathrooms-text14 copy">
                    Our team specializes in designing high-end spaces tailored
                    to your needs. With luxury features and thoughtful layouts,
                    we create a personal retreat that blends style and comfort
                    seamlessly.
                  </span>
                </div>
              </div>
            </animate-on-reveal>
          </div>
        </div>
      </div>
      <div className="bathrooms-showcase">
        <div className="bathrooms-heading">
          <span className="bathrooms-prehead2 preHead">showcase</span>
          <span className="bathrooms-text15 heading SectionHeading">
            <span>Elegant Bathrooms,</span>
            <br></br>
            <span> Expertly Crafted</span>
          </span>
        </div>
        <div className="bathrooms-row1">
          <div className="bathrooms-image10"></div>
          <div className="bathrooms-container2">
            <div className="bathrooms-text19">
              <span className="bathrooms-text20">serene ambience</span>
              <span className="bathrooms-bio1 copy">
                <span>
                  This bathroom combines crisp marble, a sleek circular mirror,
                  and custom wainscoting for a serene, timeless look.
                  Minimalistic accents and thoughtful design create a calming
                  retreat that balances modern elegance with classic touches.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="bathrooms-text22">- Redmond, WA</span>
              </span>
            </div>
          </div>
        </div>
        <div className="bathrooms-row2">
          <div className="bathrooms-image11"></div>
          <div className="bathrooms-container3">
            <div className="bathrooms-text23">
              <span className="bathrooms-text24">Natural Warmth</span>
              <span className="bathrooms-bio2 copy">
                <span>
                  Warm wood cabinetry and textured stone tiles give this space
                  natural beauty with a modern edge. The walk-in glass shower
                  and subtle lighting bring a luxurious, functional design
                  perfect for everyday indulgence. 
                </span>
                <span className="bathrooms-text26">- Bothell, WA</span>
              </span>
            </div>
          </div>
        </div>
        <div className="bathrooms-row3">
          <div className="bathrooms-image12"></div>
          <div className="bathrooms-container4">
            <div className="bathrooms-text27">
              <span className="bathrooms-text28">Spa-Like Luxury</span>
              <span className="bathrooms-bio3 copy">
                <span>
                  With a freestanding tub, walk-in shower, and skylight, this
                  bathroom feels like a personal spa. Sleek tilework and custom
                  shelving elevate the space, blending luxury with modern
                  sophistication. 
                </span>
                <span className="bathrooms-text30">- Woodinville, WA</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="bathrooms-luxury-features">
        <animate-on-reveal
          animation="fadeIn"
          duration="1000ms"
          delay="0s"
          direction="normal"
          easing="ease-out"
          iteration="1"
        >
          <div
            data-thq-animate-on-reveal="true"
            className="bathrooms-heading-container2"
          >
            <span className="Prehead preHead bathrooms-prehead3">features</span>
            <h1 className="bathrooms-text31 SectionHeading heading">
              <span>crafted for pure luxury</span>
              <br></br>
            </h1>
          </div>
        </animate-on-reveal>
        <div className="bathrooms-mission-small-cover">
          <div className="bathrooms-item1">
            <div className="bathrooms-image13"></div>
            <div className="bathrooms-details1">
              <span className="material-title">walk-in showers</span>
              <span className="bathrooms-text34 copy">
                Walk-in showers offer spacious, modern design with a touch of
                sleek accessibility.
              </span>
            </div>
          </div>
          <div className="bathrooms-item2">
            <div className="bathrooms-image14"></div>
            <div className="bathrooms-details2">
              <span className="material-title">waterfall faucets</span>
              <span className="bathrooms-text35 copy">
                Waterfall faucets bring the calming flow of nature into your
                everyday routine.
              </span>
            </div>
          </div>
          <div className="bathrooms-item3">
            <div className="bathrooms-image15"></div>
            <div className="bathrooms-details3">
              <span className="material-title">freestanding bathtubs</span>
              <span className="bathrooms-text36 copy">
                A freestanding tub serves as a luxurious focal point, perfect
                for unwinding in style.
              </span>
            </div>
          </div>
          <div className="bathrooms-item4">
            <div className="bathrooms-image16"></div>
            <div className="bathrooms-details4">
              <span className="material-title">heated floors</span>
              <span className="bathrooms-text37 copy">
                A cozy touch that transforms chilly mornings into moments of
                comfort.
              </span>
            </div>
          </div>
          <div className="bathrooms-item5">
            <div className="bathrooms-image17"></div>
            <div className="bathrooms-details5">
              <span className="material-title">rainfall showers</span>
              <span className="bathrooms-text38 copy">
                {' '}
                Immerse yourself in the peaceful sensation of natural rainfall
                every time you shower.
              </span>
            </div>
          </div>
          <div className="bathrooms-item6">
            <div className="bathrooms-image18"></div>
            <div className="bathrooms-details6">
              <span className="material-title">skylights</span>
              <span className="bathrooms-text39 copy">
                Let natural light pour in, enhancing both the mood and
                functionality of your space.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="bathrooms-mirrors-and-lighting">
        <div className="bathrooms-max-width2">
          <div className="bathrooms-float">
            <div className="bathrooms-content1">
              <span className="bathrooms-prehead4">lighting</span>
              <span className="bathrooms-text40 SectionHeading">
                designing the perfect ambience
              </span>
              <div className="bathrooms-image19"></div>
              <span className="bathrooms-text41">
                At Craft &amp; Forge, we recognize that lighting is not just a
                functional element of bathroom design; it is a crucial component
                that elevates the overall atmosphere. Our lighting approach
                harmonizes practicality with aesthetics, ensuring each bathroom
                feels both inviting and luxurious.
              </span>
              <span className="bathrooms-text42">
                <span>
                  We prioritize natural light by incorporating
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="bathrooms-text44">windows and skylights</span>
                <span>
                  {' '}
                  that fill the space with energizing daylight and enhance its
                  beauty. Our carefully selected
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="bathrooms-text46">ceiling lights</span>
                <span>
                  {' '}
                  provide even illumination, ensuring every area remains
                  accessible and functional. Unique
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="bathrooms-text48">light fixtures</span>
                <span>
                  {' '}
                  add a personalized touch, serving as both decorative elements
                  and focused light sources. Finally,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="bathrooms-text50">
                  edge and ambient lighting
                </span>
                <span>
                  {' '}
                  create layers that evoke tranquility, transforming the
                  bathroom into a serene retreat for relaxation.
                </span>
              </span>
            </div>
          </div>
          <div className="bathrooms-image20"></div>
        </div>
      </div>
      <div className="bathrooms-mirrors-and-lighting2">
        <div className="bathrooms-max-width3">
          <div className="bathrooms-head7">
            <span className="bathrooms-prehead5">lighting</span>
            <span className="bathrooms-text52 SectionHeading">
              designing the perfect ambience
            </span>
          </div>
          <div className="bathrooms-image21"></div>
          <div className="bathrooms-content2">
            <span className="bathrooms-text53">
              At Craft &amp; Forge, we recognize that lighting is not just a
              functional element of bathroom design; it is a crucial component
              that elevates the overall atmosphere. Our lighting approach
              harmonizes practicality with aesthetics, ensuring each bathroom
              feels both inviting and luxurious.
            </span>
            <span className="bathrooms-text54">
              <span>
                <span>
                  We prioritize natural light by incorporating
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span className="bathrooms-text58">windows and skylights</span>
              <span>
                <span>
                  {' '}
                  that fill the space with energizing daylight and enhance its
                  beauty. Our carefully selected
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span className="bathrooms-text62">ceiling lights</span>
              <span>
                <span>
                  {' '}
                  provide even illumination, ensuring every area remains
                  accessible and functional. Unique
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span className="bathrooms-text66">light fixtures</span>
              <span>
                <span>
                  {' '}
                  add a personalized touch, serving as both decorative elements
                  and focused light sources. Finally,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span className="bathrooms-text70">
                edge and ambient lighting
              </span>
              <span>
                {' '}
                create layers that evoke tranquility, transforming the bathroom
                into a serene retreat for relaxation.
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="bathrooms-cta">
        <div className="bathrooms-max-width4">
          <div className="bathrooms-image22"></div>
          <div className="bathrooms-content3">
            <div className="bathrooms-content4">
              <h1 className="bathrooms-text72 heading SectionHeading">
                transform your bathroom
              </h1>
              <span className="bathrooms-text73 copy">
                Our team delivers high-quality renovations that reflect your
                personality. Reach out to discuss your ideas and create your
                perfect bathroom!
              </span>
              <Link to="/contact" className="bluebutton Content">
                <span>Schedule a consultation</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer
        link11={
          <Fragment>
            <span className="bathrooms-text75">Full Floor Remodels</span>
          </Fragment>
        }
        text10={
          <Fragment>
            <span className="bathrooms-text76">
              Follow us on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        text14={
          <Fragment>
            <span className="bathrooms-text77">
              <span className="bathrooms-text78">Home</span>
              <br></br>
            </span>
          </Fragment>
        }
        text24={
          <Fragment>
            <span className="bathrooms-text80">About</span>
          </Fragment>
        }
        text34={
          <Fragment>
            <span className="bathrooms-text81">Contact</span>
          </Fragment>
        }
        text91={
          <Fragment>
            <span className="bathrooms-text82">
              <span>Custom Home Builds</span>
              <br></br>
            </span>
          </Fragment>
        }
        text111={
          <Fragment>
            <span className="bathrooms-text85">Kitchen Remodels</span>
          </Fragment>
        }
        text211={
          <Fragment>
            <span className="bathrooms-text86">Bathroom Remodels</span>
          </Fragment>
        }
        text311={
          <Fragment>
            <span className="bathrooms-text87">
              <span className="bathrooms-text88">House Additions</span>
              <br></br>
            </span>
          </Fragment>
        }
        footerId="Footer"
        imageSrc="/logos/logo%20dark-200h.png"
        text1311={
          <Fragment>
            <span className="bathrooms-text90">
              ©Craft and Forge 2024 All rights reserved
            </span>
          </Fragment>
        }
      ></Footer>
    </div>
    </Wrapper>
  )
}

export default Bathrooms
