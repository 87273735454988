import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Wrapper from '../components/page-wrapper'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './kitchens.css'

const Kitchens = (props) => {
  return (
    <Wrapper>
    <div className="kitchens-container1">
      <Helmet>
        <title>Kitchens - Craft & Forge - Premium Home Remodeling</title>
        <meta
          name="description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:title"
          content="Kitchens - Craft &amp; Forge - Premium Home Remodeling"
        />
        <meta
          property="og:description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/133b66a8-437a-4fe1-8938-2c01fd5c558d/c7ed6f96-1e17-4876-a525-ef1747a6b890?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar imageSrc1="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"></Navbar>
      <div className="kitchens-hero-fade-single">
        <div className="kitchens-hero">
          <div className="kitchens-content-container">
            <animate-on-reveal
              animation="fadeInUp"
              duration="1000ms"
              delay="0s"
              direction="normal"
              easing="ease-out"
              iteration="1"
            >
              <div
                data-thq-animate-on-reveal="true"
                className="kitchens-max-width1"
              >
                <div className="kitchens-heading-container1">
                  <span className="kitchens-prehead1 preHead">Kitchens</span>
                  <span className="kitchens-main-title title">
                    <span>the heart of</span>
                    <br></br>
                    <span>the home</span>
                    <br></br>
                  </span>
                  <span className="kitchens-text14 copy">
                    Redesign your cooking space with sleek, functional design.
                    Our custom kitchen remodels enhance both beauty and
                    efficiency, creating a space tailored to elevate every meal.
                  </span>
                </div>
              </div>
            </animate-on-reveal>
          </div>
        </div>
      </div>
      <div className="kitchens-showcase">
        <div className="kitchens-heading">
          <span className="kitchens-prehead2 preHead">showcase</span>
          <span className="kitchens-text15 heading SectionHeading">
            Elevating everyday cooking
          </span>
        </div>
        <div className="kitchens-row1">
          <div className="kitchens-image10"></div>
          <div className="kitchens-container2">
            <div className="kitchens-text16">
              <span className="subHead">modern minimalism</span>
              <span className="kitchens-bio1 copy">
                <span>
                  A clean, airy kitchen with white cabinetry and black accents
                  makes this space feel timeless yet contemporary. The stainless
                  steel fridge and appliances bring in a professional-grade
                  look, while the geometric backsplash adds a subtle layer of
                  visual interest.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="kitchens-text19">- Bothell, WA</span>
              </span>
            </div>
          </div>
        </div>
        <div className="kitchens-row2">
          <div className="kitchens-image11"></div>
          <div className="kitchens-container3">
            <div className="kitchens-text20">
              <span className="subHead">Seamless Style</span>
              <span className="kitchens-bio2 copy">
                <span>
                  The gleaming quartz countertops introduce a sense of luxury
                  while maintaining durability for everyday use. Paired with
                  crisp white cabinetry and elevated with sleek hardware,
                  offering a modern yet timeless feel. Custom shelving accents
                  ensure a perfect blend of display and utility.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="kitchens-text23">- Redmond, WA</span>
              </span>
            </div>
          </div>
        </div>
        <div className="kitchens-row3">
          <div className="kitchens-image12"></div>
          <div className="kitchens-container4">
            <div className="kitchens-text24">
              <span className="kitchens-title team-title">
                founding partner
              </span>
              <span className="kitchens-text25">Dayne Richards</span>
              <span className="kitchens-bio3 copy">
                As CEO, Dayne brings expertise in construction logistics and
                project management, ensuring every remodel is completed with
                precision. His hands-on approach keeps projects on track,
                driving Craft &amp; Forge&apos;s exceptional results
              </span>
            </div>
          </div>
        </div>
        <div className="kitchens-row4">
          <div className="kitchens-image13"></div>
          <div className="kitchens-container5">
            <div className="kitchens-text26">
              <span className="subHead">bold simplicity</span>
              <span className="kitchens-bio4 copy">
                <span>
                  White cabinets paired with brass handles offer a timeless
                  look, while the marble-inspired backsplash introduces a layer
                  of sophistication. The matte black window frame and custom
                  hood provide modern touches, ensuring this kitchen is as
                  stylish as it is functional.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="kitchens-text29">- Edmonds, WA</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="kitchens-material-grid">
        <animate-on-reveal
          animation="fadeIn"
          duration="1000ms"
          delay="0s"
          direction="normal"
          easing="ease-out"
          iteration="1"
        >
          <div
            data-thq-animate-on-reveal="true"
            className="kitchens-heading-container2"
          >
            <span className="Prehead preHead kitchens-prehead3">materials</span>
            <h1 className="kitchens-text30 heading SectionHeading">
              <span>
                handpicked for
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>quality</span>
              <span> and style</span>
              <br></br>
            </h1>
          </div>
        </animate-on-reveal>
        <div className="kitchens-mission-small-cover">
          <div className="kitchens-row5">
            <div className="kitchens-item1">
              <div className="kitchens-image14">
                <div className="kitchens-gradient1"></div>
              </div>
              <div className="kitchens-details1">
                <span className="kitchens-head1 material-title">
                  mosaic tile
                </span>
                <span className="copy kitchens-text36">
                  Tile come in endless patterns and textures, allowing for bold,
                  creative installations.
                </span>
              </div>
            </div>
            <div className="kitchens-item2">
              <div className="kitchens-image15"></div>
              <div className="kitchens-details2">
                <span className="kitchens-head2 material-title"> quartz</span>
                <span className="copy kitchens-text37">
                  Quartz is non-porous and stain-resistant, requiring minimal
                  maintenance.
                </span>
              </div>
            </div>
          </div>
          <div className="kitchens-row6">
            <div className="kitchens-item3">
              <div className="kitchens-image16"></div>
              <div className="kitchens-details3">
                <span className="kitchens-head3 material-title"> plywood</span>
                <span className="copy kitchens-text38">
                  Plywood resists warping, ensuring long-lasting stability.
                </span>
              </div>
            </div>
            <div className="kitchens-item4">
              <div className="kitchens-image17">
                <div className="kitchens-gradient2"></div>
              </div>
              <div className="kitchens-details4">
                <span className="kitchens-head4 material-title"> marble</span>
                <span className="copy kitchens-text39">
                  Marble provides timeless beauty but requires sealing to
                  protect against stains and etching.
                </span>
              </div>
            </div>
          </div>
          <div className="kitchens-row7">
            <div className="kitchens-item5">
              <div className="kitchens-image18">
                <div className="kitchens-gradient3"></div>
              </div>
              <div className="kitchens-details5">
                <span className="kitchens-head5 material-title">
                  {' '}
                  solid wood
                </span>
                <span className="copy kitchens-text40">
                  Solid wood adds natural beauty to any space but may expand or
                  contract with humidity changes.
                </span>
              </div>
            </div>
            <div className="kitchens-item6">
              <div className="kitchens-image19"></div>
              <div className="kitchens-details6">
                <span className="kitchens-head6 material-title">
                  <span> porcelain</span>
                  <br></br>
                </span>
                <span className="copy kitchens-text43">
                  Porcelain provides a luxurious look with minimal upkeep
                  compared to natural stone.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="kitchens-trends">
        <div className="kitchens-max-width2">
          <div className="kitchens-image20"></div>
          <div className="kitchens-content1">
            <span className="kitchens-prehead4 preHead">
              trends &amp; innovations
            </span>
            <span className="kitchens-text44 heading">
              <span className="SectionHeading">redefining modern cooking</span>
              <br></br>
            </span>
            <span className="kitchens-text47 copy">
              Technology is transforming kitchens into smarter, more efficient
              spaces. Embrace innovation to elevate every aspect of your
              culinary routine.
            </span>
            <span className="kitchens-text48 copy">
              <span className="kitchens-text49">Smart appliances</span>
              <span>
                {' '}
                make meal prep a breeze, allowing you to enjoy a fully automated
                kitchen while your
              </span>
              <span> </span>
              <span className="kitchens-text52">
                climate-controlled wine storage
              </span>
              <span>
                {' '}
                keeps your collection at its best. Complete the morning or
                evening with a
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="kitchens-text54">built-in coffee system</span>
              <span>
                , giving you the luxury of a perfect brew at any moment.
              </span>
            </span>
            <span className="kitchens-text56 copy">
              From smart tech to luxury additions, there are endless ways to
              elevate your kitchen. Let your imagination lead the way and create
              a space that’s as functional as it is beautiful.
            </span>
          </div>
        </div>
      </div>
      <div className="kitchens-cta">
        <div className="kitchens-max-width3">
          <div className="kitchens-heading-container3">
            <div className="kitchens-content2">
              <h1 className="kitchens-text57 heading SectionHeading">
                <span>reimagine your</span>
                <br></br>
                <span> kitchen</span>
              </h1>
              <span className="kitchens-text61 copy">
                <span>
                  Ready to elevate your culinary experience?
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  Contact us now to start your kitchen transformation!
                </span>
              </span>
              <Link to="/contact" className="bluebutton Content">
                <span>Schedule a consultation</span>
              </Link>
            </div>
          </div>
          <div className="kitchens-image21">
            <img
              alt="image"
              src="/photos/kitchens/img_3618-1500w.jpeg"
              className="kitchens-image22"
            />
            <div className="kitchens-container6"></div>
          </div>
        </div>
      </div>
      <Footer
        link11={
          <Fragment>
            <span className="kitchens-text66">Full Floor Remodels</span>
          </Fragment>
        }
        text10={
          <Fragment>
            <span className="kitchens-text67">
              Follow us on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        text14={
          <Fragment>
            <span className="kitchens-text68">
              <span className="kitchens-text69">Home</span>
              <br></br>
            </span>
          </Fragment>
        }
        text24={
          <Fragment>
            <span className="kitchens-text71">About</span>
          </Fragment>
        }
        text34={
          <Fragment>
            <span className="kitchens-text72">Contact</span>
          </Fragment>
        }
        text91={
          <Fragment>
            <span className="kitchens-text73">
              <span>Custom Home Builds</span>
              <br></br>
            </span>
          </Fragment>
        }
        text111={
          <Fragment>
            <span className="kitchens-text76">Kitchen Remodels</span>
          </Fragment>
        }
        text211={
          <Fragment>
            <span className="kitchens-text77">Bathroom Remodels</span>
          </Fragment>
        }
        text311={
          <Fragment>
            <span className="kitchens-text78">
              <span className="kitchens-text79">House Additions</span>
              <br></br>
            </span>
          </Fragment>
        }
        footerId="Footer"
        imageSrc="/logos/logo%20dark-200h.png"
        text1311={
          <Fragment>
            <span className="kitchens-text81">
              ©Craft and Forge 2024 All rights reserved
            </span>
          </Fragment>
        }
      ></Footer>
    </div>
    </Wrapper>
  )
}

export default Kitchens
