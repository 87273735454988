import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Wrapper from '../components/page-wrapper'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './renovations-additions.css'

const RenovationsAdditions = (props) => {
  return (
    <Wrapper>
    <div className="renovations-additions-container1">
      <Helmet>
        <title>
          RenovationsAdditions - Craft & Forge - Premium Home Remodeling
        </title>
        <meta
          name="description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:title"
          content="RenovationsAdditions - Craft &amp; Forge - Premium Home Remodeling"
        />
        <meta
          property="og:description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/133b66a8-437a-4fe1-8938-2c01fd5c558d/c7ed6f96-1e17-4876-a525-ef1747a6b890?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar imageSrc1="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"></Navbar>
      <div className="renovations-additions-hero-fade-single">
        <div className="renovations-additions-hero">
          <div className="renovations-additions-content-container">
            <animate-on-reveal
              animation="fadeInUp"
              duration="1000ms"
              delay="0s"
              direction="normal"
              easing="ease-out"
              iteration="1"
            >
              <div
                data-thq-animate-on-reveal="true"
                className="renovations-additions-max-width1"
              >
                <div className="renovations-additions-heading-container1">
                  <span className="renovations-additions-prehead1 preHead">
                    renovations &amp; additions
                  </span>
                  <span className="renovations-additions-main-title title">
                    <span>reimagining spaces</span>
                    <br></br>
                    <span>with elegance</span>
                    <br></br>
                  </span>
                  <span className="renovations-additions-text14 copy">
                    Each project is an opportunity to redefine what your home
                    can be. Craft &amp; Forge combines creativity with precision
                    to shape spaces that inspire and endure.
                  </span>
                </div>
              </div>
            </animate-on-reveal>
          </div>
        </div>
      </div>
      <div className="renovations-additions-showcase">
        <div className="renovations-additions-heading">
          <span className="renovations-additions-prehead2 preHead">
            showcase
          </span>
          <span className="renovations-additions-text15 heading SectionHeading">
            expanding boundaries, enhancing comfort
          </span>
        </div>
        <div className="renovations-additions-row1">
          <div className="renovations-additions-image10"></div>
          <div className="renovations-additions-container2">
            <div className="renovations-additions-text16">
              <span className="renovations-additions-text17">
                seamless expansion
              </span>
              <span className="renovations-additions-bio1 copy">
                <span>
                  The addition, featuring a contemporary chimney, small deck,
                  sliding glass doors, and multiple windows, embodies our
                  commitment to sleek and sophisticated design, allowing natural
                  light to flood the space.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="renovations-additions-text19">
                  - Bothell, WA
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="renovations-additions-row2">
          <div className="renovations-additions-image11"></div>
          <div className="renovations-additions-container3">
            <div className="renovations-additions-text20">
              <span className="renovations-additions-text21">
                modern elegance
              </span>
              <span className="renovations-additions-bio2 copy">
                <span>
                  The use of minimalist materials and clean lines highlights a
                  commitment to understated luxury. With expansive windows and
                  elevated sightlines, the space exudes sophistication and
                  timeless appeal.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="renovations-additions-text23">
                  - Bothell, WA
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="renovations-additions-row3">
          <div className="renovations-additions-image12"></div>
          <div className="renovations-additions-container4">
            <div className="renovations-additions-text24">
              <span className="renovations-additions-text25">
                tranquil retreat
              </span>
              <span className="renovations-additions-bio3 copy">
                <span>
                  This bedroom features a calming shiplap accent wall in a soft
                  blue-gray, bringing texture and a coastal feel to the
                  space. Paired with subtle decor, the design enhances the
                  room&apos;s serene, relaxing atmosphere.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="renovations-additions-text27">
                  - Bothell, WA
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="renovations-additions-material-grid">
        <animate-on-reveal
          animation="fadeIn"
          duration="1000ms"
          delay="0s"
          direction="normal"
          easing="ease-out"
          iteration="1"
        >
          <div
            data-thq-animate-on-reveal="true"
            className="renovations-additions-heading-container2"
          >
            <span className="preHead Prehead renovations-additions-prehead3">
              Remodel categories
            </span>
            <h1 className="renovations-additions-text28 SectionHeading heading">
              <span>dream, design,</span>
              <br></br>
              <span>transform</span>
              <br></br>
            </h1>
          </div>
        </animate-on-reveal>
        <div className="renovations-additions-type-container">
          <div className="renovations-additions-collumn">
            <div className="renovations-additions-type1">
              <img
                alt="image"
                src="/photos/remodels_additions/categories/337363468_744375143758454_4807546306070999285_n-500h.jpg"
                className="renovations-additions-image13"
              />
              <div className="renovations-additions-text33">
                <span className="renovations-additions-text34">
                  <span>bedrooms</span>
                  <br></br>
                </span>
                <span className="renovations-additions-bio4 copy">
                  Transform your bedroom into a serene retreat tailored to your
                  style. Maximize space with built-in storage or create a cozy
                  reading nook. A bedroom remodel enhances comfort and promotes
                  better rest, making it the perfect sanctuary for relaxation.
                </span>
              </div>
            </div>
            <div className="renovations-additions-type2">
              <div className="renovations-additions-text37">
                <span className="renovations-additions-text38">
                  living rooms
                </span>
                <span className="renovations-additions-bio5 copy">
                  Elevate your living room into a stunning centerpiece for
                  gatherings and everyday life. Enhance flow and functionality,
                  incorporate modern entertainment features, and create an
                  inviting atmosphere that reflects your unique aesthetic.
                </span>
              </div>
              <img
                alt="image"
                src="/photos/remodels_additions/categories/snapinsta.app_175689453_498433691339162_7853300637202778879_n_1080-600w.jpg"
                className="renovations-additions-image14"
              />
            </div>
            <div className="renovations-additions-type3">
              <img
                alt="image"
                src="/photos/remodels_additions/categories/c1a64eb7--600w.jpg"
                className="renovations-additions-image15"
              />
              <div className="renovations-additions-text39">
                <span className="renovations-additions-text40">
                  <span>rooftop deck</span>
                  <br></br>
                </span>
                <span className="renovations-additions-bio6 copy">
                  A rooftop deck creates an outdoor oasis high above the bustle.
                  This unique space can serve as a tranquil retreat or vibrant
                  entertainment area. Incorporate seating, planters, and even a
                  small outdoor kitchen for a beautiful extension of your home.
                </span>
              </div>
            </div>
            <div className="renovations-additions-type4">
              <div className="renovations-additions-text43">
                <span className="renovations-additions-text44">
                  room additions
                </span>
                <span className="renovations-additions-bio7 copy">
                  Expanding with a room addition provides space for a growing
                  family, home office, or hobby room. Customize your space to
                  fit your lifestyle, seamlessly integrating new areas while
                  enhancing the overall value of your home.
                </span>
              </div>
              <img
                alt="image"
                src="/photos/remodels_additions/categories/84001e38-963f-41be-bab8-bc7566aac4e5%20cropped-500h.jpg"
                className="renovations-additions-image16"
              />
            </div>
            <div className="renovations-additions-type5">
              <img
                alt="image"
                src="/photos/remodels_additions/categories/railing%2031-600w.png"
                className="renovations-additions-image17"
              />
              <div className="renovations-additions-text45">
                <span className="renovations-additions-text46">
                  <span>floor additions</span>
                  <br></br>
                </span>
                <span className="renovations-additions-bio8 copy">
                  Adding a new floor can dramatically increase living space and
                  property value. Create additional bedrooms, an expansive
                  master suite, or a multipurpose area while gaining spectacular
                  views and ample natural light.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="renovations-additions-integration">
        <div className="renovations-additions-max-width2">
          <div className="renovations-additions-image18"></div>
          <div className="renovations-additions-float">
            <div className="renovations-additions-content1">
              <span className="renovations-additions-prehead4 preHead">
                architectural integration
              </span>
              <span className="renovations-additions-text49 SectionHeading">
                seamless design harmony
              </span>
              <span className="renovations-additions-text50">
                <span>
                  Every home is unique, which is why our approach to
                  architectural integration is tailored to your specific needs.
                  We begin by assessing your home’s
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="renovations-additions-text52">
                  existing architecture and surroundings
                </span>
                <span>
                  , identifying opportunities to create an addition that
                  enhances both form and function. By thoughtfully considering
                  factors like
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="renovations-additions-text54">
                  sightlines, access points, and existing structures
                </span>
                <span>
                  , we ensure that your new space feels cohesive with the
                  original design.
                </span>
                <span className="Content">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br className="Content"></br>
              </span>
              <span className="renovations-additions-text58">
                <span>
                  Our skilled architects and designers work diligently to marry
                  innovative design with traditional aesthetics. The goal is to
                  create additions that not only fulfill your requirements but
                  also maintain
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="renovations-additions-text60">
                  the character and charm of your home
                </span>
                <span>
                  . With Craft &amp; Forge, you can trust that your addition
                  will be a beautifully integrated enhancement, transforming
                  your living space while preserving its essence.
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="renovations-additions-cta">
        <div className="renovations-additions-max-width3">
          <div className="renovations-additions-image19"></div>
          <div className="renovations-additions-content2">
            <div className="renovations-additions-content3">
              <h1 className="renovations-additions-text62 heading SectionHeading">
                redesign your home
              </h1>
              <span className="renovations-additions-text63 copy">
                Don’t wait to make your dream home a reality. Connect with us
                for a consultation, and let’s bring your ideas to life!
              </span>
              <Link to="/contact" className="bluebutton Content">
                <span>Schedule a consultation</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer
        link11={
          <Fragment>
            <span className="renovations-additions-text65">
              Full Floor Remodels
            </span>
          </Fragment>
        }
        text10={
          <Fragment>
            <span className="renovations-additions-text66">
              Follow us on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        text14={
          <Fragment>
            <span className="renovations-additions-text67">
              <span className="renovations-additions-text68">Home</span>
              <br></br>
            </span>
          </Fragment>
        }
        text24={
          <Fragment>
            <span className="renovations-additions-text70">About</span>
          </Fragment>
        }
        text34={
          <Fragment>
            <span className="renovations-additions-text71">Contact</span>
          </Fragment>
        }
        text91={
          <Fragment>
            <span className="renovations-additions-text72">
              <span>Custom Home Builds</span>
              <br></br>
            </span>
          </Fragment>
        }
        text111={
          <Fragment>
            <span className="renovations-additions-text75">
              Kitchen Remodels
            </span>
          </Fragment>
        }
        text211={
          <Fragment>
            <span className="renovations-additions-text76">
              Bathroom Remodels
            </span>
          </Fragment>
        }
        text311={
          <Fragment>
            <span className="renovations-additions-text77">
              <span className="renovations-additions-text78">
                House Additions
              </span>
              <br></br>
            </span>
          </Fragment>
        }
        footerId="Footer"
        imageSrc="/logos/logo%20dark-200h.png"
        text1311={
          <Fragment>
            <span className="renovations-additions-text80">
              ©Craft and Forge 2024 All rights reserved
            </span>
          </Fragment>
        }
      ></Footer>
    </div>
    </Wrapper>
  )
}

export default RenovationsAdditions
