import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Wrapper from '../components/page-wrapper'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './custom-homes.css'

const CustomHomes = (props) => {
  return (
    <Wrapper>
    <div className="custom-homes-container1">
      <Helmet>
        <title>CustomHomes - Craft & Forge - Premium Home Remodeling</title>
        <meta
          name="description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:title"
          content="CustomHomes - Craft &amp; Forge - Premium Home Remodeling"
        />
        <meta
          property="og:description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/133b66a8-437a-4fe1-8938-2c01fd5c558d/c7ed6f96-1e17-4876-a525-ef1747a6b890?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar imageSrc1="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"></Navbar>
      <div className="custom-homes-hero-fade-single">
        <div className="custom-homes-hero">
          <div className="custom-homes-content-container">
            <animate-on-reveal
              animation="fadeInUp"
              duration="1000ms"
              delay="0s"
              direction="normal"
              easing="ease-out"
              iteration="1"
            >
              <div
                data-thq-animate-on-reveal="true"
                className="custom-homes-max-width1"
              >
                <div className="custom-homes-heading-container1">
                  <span className="preHead">custom homes</span>
                  <span className="custom-homes-main-title title">
                    <span>
                      build your
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>dream home</span>
                    <br></br>
                  </span>
                  <span className="custom-homes-text14 copy">
                    Your journey to a bespoke home begins with a vision, and
                    we’re here to help you realize it. With our expertise and
                    attention to detail, we’ll transform your ideas into a
                    beautifully crafted reality.
                  </span>
                </div>
              </div>
            </animate-on-reveal>
          </div>
        </div>
      </div>
      <div className="custom-homes-showcase">
        <div className="custom-homes-heading">
          <span className="custom-homes-prehead2 preHead">
            <span>our design process</span>
            <br></br>
          </span>
          <span className="custom-homes-text17 heading SectionHeading">
            <span>
              personalized from
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>start to finish</span>
          </span>
        </div>
        <div className="custom-homes-row1">
          <div className="custom-homes-image10"></div>
          <div className="custom-homes-container2">
            <div className="custom-homes-text21">
              <span className="custom-homes-text22">vision mapping</span>
              <span className="custom-homes-bio1 copy">
                Our journey starts with a comprehensive consultation to
                understand your lifestyle, tastes, and aspirations for your
                home. This in-depth session allows us to tailor every aspect of
                the design to reflect your unique vision.
              </span>
            </div>
          </div>
        </div>
        <div className="custom-homes-row2">
          <div className="custom-homes-image11"></div>
          <div className="custom-homes-container3">
            <div className="custom-homes-text23">
              <span className="custom-homes-text24">collaborative design</span>
              <span className="custom-homes-bio2 copy">
                Our design phase is a partnership, inviting your input on every
                aspect, from room layouts to unique additions. We work closely
                with you to ensure every detail of your home reflects your
                unique style and preferences.
              </span>
            </div>
          </div>
        </div>
        <div className="custom-homes-row3">
          <div className="custom-homes-image12"></div>
          <div className="custom-homes-container4">
            <div className="custom-homes-text25">
              <span className="custom-homes-text26">3d visualization</span>
              <span className="custom-homes-bio3 copy">
                We gather all critical site details and produce detailed 3D
                renderings, so you can visualize the home before building
                begins. This immersive approach provides you the confidence that
                every detail has been considered and perfected.
              </span>
            </div>
          </div>
        </div>
        <div className="custom-homes-row4">
          <div className="custom-homes-image13"></div>
          <div className="custom-homes-container5">
            <div className="custom-homes-text27">
              <span className="custom-homes-text28">material selection</span>
              <span className="custom-homes-bio4 copy">
                Craft &amp; Forge prioritizes quality in every choice, offering
                access to top-grade, sustainable materials. With access to
                premium resources, we help bring your home’s style and quality
                to life.
              </span>
            </div>
          </div>
        </div>
        <div className="custom-homes-row5">
          <div className="custom-homes-image14"></div>
          <div className="custom-homes-container6">
            <div className="custom-homes-text29">
              <span className="custom-homes-text30">point of CONTACT</span>
              <span className="custom-homes-bio5 copy">
                Our project manager is your go-to, keeping you updated and
                addressing any questions you may have. We believe in full
                transparency, providing you regular updates and an open line of
                communication.
              </span>
            </div>
          </div>
        </div>
        <div className="custom-homes-row6">
          <div className="custom-homes-image15"></div>
          <div className="custom-homes-container7">
            <div className="custom-homes-text31">
              <span className="custom-homes-text32">personal touch</span>
              <span className="custom-homes-bio6 copy">
                Before completion, we walk you through your home, fine-tuning
                details to meet your vision. This final review ensures the end
                result is everything you dreamed of and more.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-homes-grid-features">
        <animate-on-reveal
          animation="fadeIn"
          duration="1000ms"
          delay="0s"
          direction="normal"
          easing="ease-out"
          iteration="1"
        >
          <div
            data-thq-animate-on-reveal="true"
            className="custom-homes-heading-container2"
          >
            <span className="custom-homes-prehead3 preHead Prehead">
              premium features
            </span>
            <h1 className="custom-homes-text33">
              <span className="SectionHeading">your vision,</span>
              <br className="SectionHeading"></br>
              <span className="SectionHeading">endless possiBILITies</span>
              <br></br>
            </h1>
            <span className="custom-homes-bio7 copy">
              Discover the possibilities of truly custom living with features
              that enhance every corner of your home. Each feature is designed
              with your lifestyle in mind, creating spaces that are both
              luxurious and functional.
            </span>
          </div>
        </animate-on-reveal>
        <div className="custom-homes-mission-small-cover1">
          <div className="custom-homes-item10">
            <div className="custom-homes-image16"></div>
            <div className="custom-homes-details10">
              <span className="custom-homes-head10 Subheading">
                {' '}
                home theater
              </span>
              <span className="custom-homes-description10">
                Bring the cinema home with a custom theater that elevates every
                movie night to a new level of comfort and immersion.
              </span>
            </div>
          </div>
          <div className="custom-homes-item11">
            <div className="custom-homes-image17"></div>
            <div className="custom-homes-details11">
              <span className="custom-homes-head11 Subheading"> home gym</span>
              <span className="custom-homes-description11">
                Achieve your fitness goals at home with a custom gym, designed
                for convenience and tailored to your regimen.
              </span>
            </div>
          </div>
          <div className="custom-homes-item12">
            <div className="custom-homes-image18"></div>
            <div className="custom-homes-details12">
              <span className="custom-homes-head12 Subheading">
                {' '}
                custom pool
              </span>
              <span className="custom-homes-description12">
                Turn your outdoor space into an oasis with a beautifully
                designed pool, ideal for gatherings or peaceful afternoons.
              </span>
            </div>
          </div>
          <div className="custom-homes-item13">
            <div className="custom-homes-image19"></div>
            <div className="custom-homes-details13">
              <span className="custom-homes-head13 Subheading">
                {' '}
                wine cellar
              </span>
              <span className="custom-homes-description13">
                A custom wine cellar adds sophistication to your home, keeping
                your favorite vintages ready for any occasion.
              </span>
            </div>
          </div>
          <div className="custom-homes-item14">
            <div className="custom-homes-image20"></div>
            <div className="custom-homes-details14">
              <span className="custom-homes-head14 Subheading"> hot tub</span>
              <span className="custom-homes-description14">
                Create a serene escape with a custom hot tub, perfect for
                soothing away the day and enjoying quiet moments.
              </span>
            </div>
          </div>
          <div className="custom-homes-item15">
            <div className="custom-homes-image21"></div>
            <div className="custom-homes-details15">
              <span className="custom-homes-head15 Subheading">
                {' '}
                solar roof
              </span>
              <span className="custom-homes-description15">
                A solar roof not only powers your home but enhances its value,
                offering clean energy and long-term savings.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-homes-grid-team">
        <animate-on-reveal
          animation="fadeIn"
          duration="1000ms"
          delay="0s"
          direction="normal"
          easing="ease-out"
          iteration="1"
        >
          <div
            data-thq-animate-on-reveal="true"
            className="custom-homes-heading-container3"
          >
            <span className="custom-homes-prehead4 Prehead preHead">
              our collaborators
            </span>
            <h1 className="custom-homes-text38 SectionHeading">
              <span className="custom-homes-text39">expert partners,</span>
              <span> </span>
              <br></br>
              <span>perfect results</span>
            </h1>
            <span className="custom-homes-bio8 copy">
              At Craft &amp; Forge, we believe in the power of collaboration to
              bring your vision to life. By working closely with engineers,
              designers, and specialists, we save you time and eliminate stress,
              ensuring every detail is executed to perfection.
            </span>
          </div>
        </animate-on-reveal>
        <div className="custom-homes-mission-small-cover2">
          <div className="custom-homes-item16">
            <div className="custom-homes-image22"></div>
            <div className="custom-homes-details16">
              <span className="custom-homes-head16 Subheading"> Archtects</span>
              <span className="custom-homes-description16">
                Architects transform your vision into a harmonious design,
                blending innovation with timeless elegance.
              </span>
            </div>
          </div>
          <div className="custom-homes-item17">
            <div className="custom-homes-image23"></div>
            <div className="custom-homes-details17">
              <span className="custom-homes-head17 Subheading">
                {' '}
                structural engineers
              </span>
              <span className="custom-homes-description17">
                Structural engineers ensure your home’s foundation is built on
                safety and strength, with meticulous attention to detail.
              </span>
            </div>
          </div>
          <div className="custom-homes-item18">
            <div className="custom-homes-image24"></div>
            <div className="custom-homes-details18">
              <span className="custom-homes-head18 Subheading">
                {' '}
                interior designers
              </span>
              <span className="custom-homes-description18">
                Interior designers craft personalized spaces that embody your
                style and elevate everyday living.
              </span>
            </div>
          </div>
          <div className="custom-homes-item19">
            <div className="custom-homes-image25"></div>
            <div className="custom-homes-details19">
              <span className="custom-homes-head19 Subheading">
                {' '}
                landscape designers
              </span>
              <span className="custom-homes-description19">
                Landscape designers enhance your outdoor spaces, seamlessly
                connecting nature with refined architecture.
              </span>
            </div>
          </div>
          <div className="custom-homes-item20">
            <div className="custom-homes-image26"></div>
            <div className="custom-homes-details20">
              <span className="custom-homes-head20 Subheading">
                {' '}
                electrical engineers
              </span>
              <span className="custom-homes-description20">
                Electrical engineers design smart systems that seamlessly power
                your home, enhancing both function and form.
              </span>
            </div>
          </div>
          <div className="custom-homes-item21">
            <div className="custom-homes-image27"></div>
            <div className="custom-homes-details21">
              <span className="custom-homes-head21 Subheading">
                {' '}
                inspectors
              </span>
              <span className="custom-homes-description21">
                Inspectors ensure every detail meets the highest standards of
                quality, delivering perfection in every corner.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-homes-trends">
        <div className="custom-homes-max-width2">
          <div className="custom-homes-image28"></div>
          <div className="custom-homes-content1">
            <span className="custom-homes-prehead5 preHead">
              continued support
            </span>
            <span className="custom-homes-text43 heading">
              <span className="SectionHeading">your home, </span>
              <span className="SectionHeading">our promise</span>
              <br></br>
            </span>
            <span className="custom-homes-text47 copy">
              <span>
                Your custom home is crafted with precision, and we guarantee
                that every aspect meets the highest quality standards. Craft
                &amp; Forge’s
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="custom-homes-text49">
                quality guarantee
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                covers all facets of construction, from the foundation to the
                finishes, to ensure your home reflects the excellence you
                expect.
              </span>
            </span>
            <span className="custom-homes-text51 copy">
              <span>
                Alongside our quality commitment, our
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="custom-homes-text53">home warranty</span>
              <span>
                {' '}
                covers key systems and features, protecting your investment and
                giving you confidence. Our support extends beyond the building
                phase; we’re here to assist with any future needs, adjustments,
                or maintenance. With Craft &amp; Forge, your home isn’t just
                built—it’s protected for the long term.
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="custom-homes-cta">
        <div className="custom-homes-max-width3">
          <div className="custom-homes-heading-container4">
            <div className="custom-homes-content2">
              <h1 className="custom-homes-text55 SectionHeading heading">
                <span>craft your</span>
                <br></br>
                <span> perfect space</span>
              </h1>
              <span className="custom-homes-text59 copy">
                Ready to bring your vision to life? Let Craft &amp; Forge guide
                you through every step of creating your dream home.
              </span>
              <Link to="/contact" className="bluebutton Content">
                <span>Schedule a consultation</span>
              </Link>
            </div>
          </div>
          <div className="custom-homes-image29">
            <img
              alt="image"
              src="/photos/custom_homes/francesca-tosolini-ll9v0impstm-unsplash-1500w.jpg"
              className="custom-homes-image30"
            />
            <div className="custom-homes-container8"></div>
          </div>
        </div>
      </div>
      <Footer
        link11={
          <Fragment>
            <span className="custom-homes-text61">Full Floor Remodels</span>
          </Fragment>
        }
        text10={
          <Fragment>
            <span className="custom-homes-text62">
              Follow us on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        text14={
          <Fragment>
            <span className="custom-homes-text63">
              <span className="custom-homes-text64">Home</span>
              <br></br>
            </span>
          </Fragment>
        }
        text24={
          <Fragment>
            <span className="custom-homes-text66">About</span>
          </Fragment>
        }
        text34={
          <Fragment>
            <span className="custom-homes-text67">Contact</span>
          </Fragment>
        }
        text91={
          <Fragment>
            <span className="custom-homes-text68">
              <span>Custom Home Builds</span>
              <br></br>
            </span>
          </Fragment>
        }
        text111={
          <Fragment>
            <span className="custom-homes-text71">Kitchen Remodels</span>
          </Fragment>
        }
        text211={
          <Fragment>
            <span className="custom-homes-text72">Bathroom Remodels</span>
          </Fragment>
        }
        text311={
          <Fragment>
            <span className="custom-homes-text73">
              <span className="custom-homes-text74">House Additions</span>
              <br></br>
            </span>
          </Fragment>
        }
        footerId="Footer"
        imageSrc="/logos/logo%20dark-200h.png"
        text1311={
          <Fragment>
            <span className="custom-homes-text76">
              ©Craft and Forge 2024 All rights reserved
            </span>
          </Fragment>
        }
      ></Footer>
    </div>
    </Wrapper>
  )
}

export default CustomHomes
