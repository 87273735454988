import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './contact-popup.css'

const ContactPopup = (props) => {
  const [isVisible, setIsVisible] = useState(false)
  props.popupHook.run = () => {
    setIsVisible(true);
  }

  return (
    <div className={`contact-popup-container ${props.rootClassName} `}>
      {isVisible && (
        <div className="contact-popup-bg">
          <div className="contact-popup-content-container">
            <span className="contact-popup-text10 Prehead">Success!</span>
            <span className="contact-popup-text11">
              <span>Y</span>
              <span>our next step to luxury begins now.</span>
            </span>
            <span className="contact-popup-text14">
              <span className="Content">
                Thank you for choosing
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="contact-popup-text16">Craft &amp; Forge</span>
              <span className="Content">
                . Our specialist will
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="Content">
                connect with you soon to discuss your project.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="Content"></br>
              <span className="Content">Your remodel </span>
              <span className="Content">is just around the corner.</span>
            </span>
            <img
              alt="image"
              src="/icons/cross-200h.png"
              onClick={() => setIsVisible(false)}
              className="contact-popup-image"
            />
          </div>
        </div>
      )}
    </div>
  )
}

ContactPopup.defaultProps = {
  rootClassName: '',
}

ContactPopup.propTypes = {
  rootClassName: PropTypes.string,
}

export default ContactPopup
