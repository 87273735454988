import { motion, useInView, useAnimate, animate } from 'framer-motion'
import { useEffect, useRef } from 'react';

//initial = the element's state before animating
//animate = the element's state after animating
//the element animates between the two states
export default (props) => { 
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, amount:props.amount ?? .5 });

    return (
        <motion.div
            ref={ref}
            className={props.className}
            initial={props.initial}
            animate={isInView ? props.animate : props.initial}
            transition={props.transition ?? { duration: .5 }}
            onAnimationComplete={()=>{console.log('animation complete')}}
        >
            {props.children}
        </motion.div>
    );
};