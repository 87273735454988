import { motion } from 'framer-motion'


export default ({children}) => {
    return (
        <motion.div
            // initial={{ opacity: 0}}
            // animate={{ opacity: 1}}
            exit={{ opacity: 0, position: 'absolute' }}
            transition={{duration: .2}}
            style={{
                top: 0,
                left:0,
                width: '100%'
            }}
        >
            {children}
        </motion.div>
    );
};