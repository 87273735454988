import React, { useState, useEffect } from 'react'


import './project-highlight.css'
import { AnimatePresence, motion } from 'framer-motion'

const ProjectHighlight = (props) => {
  const [projectSelection, setProjectSelection] = useState(0)
  const [autoScroll, setAutoScroll] = useState(true)

  useEffect(() => {
    if(!autoScroll) return;
    let timer = setTimeout(() => {
      setProjectSelection(projectSelection + 1 > 4 ? 0 : projectSelection + 1);
    }, 5000)
    return () => {
      clearTimeout(timer)
    }
  }, [projectSelection])


  return (
    <div className="project-highlight-project-highlight">
      <animate-on-reveal
        animation="fadeIn"
        duration="1000ms"
        delay="0s"
        direction="normal"
        easing="ease-out"
        iteration="1"
      >
        <div
          data-thq-animate-on-reveal="true"
          className="project-highlight-heading-container"
        >
          <span className="project-highlight-prehead Prehead">Our Work</span>
          <h1 className="project-highlight-text10 SectionHeading">
            <span>elevated designs,</span>
            <br></br>
            <span>showcased</span>
          </h1>
        </div>
      </animate-on-reveal>
      <div style={{position:'relative', width:'100%', maxWidth:1000, overflow:'hidden'}}>
      <AnimatePresence>
      {projectSelection === 0 && (
        <motion.div key={0} className="project-highlight-project0"
        exit={{ opacity: 0, position: 'absolute', margin:'auto', zIndex:5 }}
        transition={{duration: .75}}
        style={{
          opacity:1,
          top: 0,
          left:0,
          bottom:0,
          right:0,
          zIndex: 0,
        }}
        >
          <div className="project-highlight-photo1">
            <img
              alt="image"
              src="/photos/home/photo package/162040081_463948628084164_9141381592977607458_n.jpg"
              className="project-highlight-image5"
            />
            <div className="project-highlight-container10"></div>
          </div>
          <div className="project-highlight-details1">
            <span className="project-highlight-head1 Subheading">
              sleek and functional kitchen overhaul
            </span>
            <span className="project-highlight-location1">
              <span className="Content">
                Location:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="project-highlight-text15">Bothell WA</span>
            </span>
            <span className="project-highlight-description1">
              Featuring crisp white cabinetry and high-end stainless steel
              appliances, this kitchen exudes modern sophistication. The
              geometric backsplash adds subtle texture, while the clean lines
              and black accents make a bold statement in this elegantly
              reimagined space.
            </span>
            <div className="project-highlight-container11"></div>
          </div>
        </motion.div>
      )}
      {projectSelection === 1 && (
        <motion.div key={1} className="project-highlight-project1"
        exit={{ opacity: 0, position: 'absolute', margin:'auto', zIndex:5}}
        transition={{duration: .75}}
        style={{
            opacity:1,
            top: 0,
            left:0,
            bottom:0,
            right:0,
            zIndex: 0,
        }}
        >
          <div className="project-highlight-photo2">
            <img
              alt="image"
              src="/photos/home/photo package/234775954_869307217030980_5059897748366147267_n.jpg"
              className="project-highlight-image6"
            />
            <div className="project-highlight-container12"></div>
          </div>
          <div className="project-highlight-details2">
            <span className="project-highlight-head2 Subheading">
              Sophisticated minimalism in a costal-inspired bath
            </span>
            <span className="project-highlight-location2">
              <span className="Content">
                Location:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="project-highlight-text17">Redmond WA</span>
            </span>
            <span className="project-highlight-description2">
              Inspired by coastal tranquility, this bathroom features a subtle
              blend of modern and classic elements. The soft blue wainscoting
              evokes a calm coastal feel, while the marble countertop and matte
              black mirror add a touch of sophistication. Finely detailed with
              polished chrome fixtures and minimalist decor, this space offers
              refined relaxation with a modern edge.
            </span>
            <div className="project-highlight-container13"></div>
          </div>
        </motion.div>
      )}
      {projectSelection === 2 && (
        <motion.div key={2} className="project-highlight-project2"
        exit={{ opacity: 0, position: 'absolute', margin:'auto', zIndex:5 }}
        transition={{duration: .75}}
        style={{
          opacity:1,
          top: 0,
          left:0,
          bottom:0,
          right:0,
          zIndex: 0,
        }}
        >
          <div className="project-highlight-photo3">
            <img
              alt="image"
              src="/photos/home/photo package/img_0147-1000w.jpg"
              className="project-highlight-image7"
            />
            <div className="project-highlight-container14"></div>
          </div>
          <div className="project-highlight-details3">
            <span className="project-highlight-head3 Subheading">
              master suite addition with modern features
            </span>
            <span className="project-highlight-location3">
              <span className="Content">
                Location:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="project-highlight-text19">Edmonds WA</span>
            </span>
            <span className="project-highlight-description3">
              Clean lines and subtle lighting define this refined living room.
              Custom shelves, paired with the minimalist fireplace and floating
              TV design, create a seamless integration of entertainment and
              storage, while natural wood accents soften the modern look,
              delivering both elegance and functionality.
            </span>
            <div className="project-highlight-container15"></div>
          </div>
        </motion.div>
      )}
      {projectSelection === 3 && (
        <motion.div key={3} className="project-highlight-project3"
        exit={{ opacity: 0, position: 'absolute', margin:'auto', zIndex:5 }}
        transition={{duration: .75}}
        style={{
          opacity:1,
          top: 0,
          left:0,
          bottom:0,
          right:0,
          zIndex: 0,
        }}
        >
          <div className="project-highlight-photo4">
            <img
              alt="image"
              src="/photos/home/photo package/image3%20(1)-min%20(2).jpeg"
              className="project-highlight-image8"
            />
            <div className="project-highlight-container16"></div>
          </div>
          <div className="project-highlight-details4">
            <span className="project-highlight-head4 Subheading">
              luxury kitchen renovation with intelligent design
            </span>
            <span className="project-highlight-location4">
              <span className="Content">
                Location:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="project-highlight-text21">Edmonds WA</span>
            </span>
            <span className="project-highlight-description4">
              This refined kitchen redesign exudes sophistication with its
              seamless integration of modern materials. The marble-effect
              countertops contrast beautifully with the custom-designed black
              island, while subtle wood accents add warmth and
              texture. Completed with premium finishes and thoughtful lighting,
              this space exemplifies the balance between luxury and
              practicality.
            </span>
            <div className="project-highlight-container17"></div>
          </div>
        </motion.div>
      )}
      {projectSelection === 4 && (
        <motion.div key={4} className="project-highlight-project4"
        exit={{ opacity: 0, position: 'absolute', margin:'auto', zIndex:5 }}
        transition={{duration: .75}}
        style={{
          opacity:1,
          top: 0,
          left:0,
          bottom:0,
          right:0,
          zIndex: 0,
        }}
        >
          <div className="project-highlight-photo5">
            <img
              alt="image"
              src="/photos/home/photo package/337428342_553691676601498_7416457620280275304_n-1000w.jpg"
              className="project-highlight-image9"
            />
            <div className="project-highlight-container18"></div>
          </div>
          <div className="project-highlight-details5">
            <span className="project-highlight-head5 Subheading">
              Timeless bedroom retreat with textural contrast
            </span>
            <span className="project-highlight-location5">
              <span className="Content">
                Location:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="project-highlight-text23">Redmond WA</span>
            </span>
            <span className="project-highlight-description5">
              Designed with both relaxation and style in mind, this bedroom
              exudes timeless appeal. The custom shiplap accent wall brings
              texture and depth, while the minimalist frosted glass door adds a
              contemporary flair. Neutral tones and fine craftsmanship come
              together to create an oasis of comfort and sophistication,
              offering a perfect retreat from everyday life.
            </span>
            <div className="project-highlight-container19"></div>
          </div>
        </motion.div>
      )}
      </AnimatePresence>
      </div>
      <div className="project-highlight-project-selection">
        <img
          alt="image"
          src="/photos/home/photo package/162040081_463948628084164_9141381592977607458_n.jpg"
          onClick={() => {setAutoScroll(false);setProjectSelection(0)}}
          className="project-highlight-image0 project-selection"
          style={projectSelection == 0 ? {opacity: 1} : {}}
        />
        <img
          alt="image"
          src="/photos/home/photo package/234775954_869307217030980_5059897748366147267_n.jpg"
          onClick={() => {setAutoScroll(false);setProjectSelection(1)}}
          className="project-highlight-image1 project-selection"
          style={projectSelection == 1 ? {opacity: 1} : {}}
        />
        <img
          alt="image"
          src="/photos/home/photo package/img_0147-1000w.jpg"
          onClick={() => {setAutoScroll(false);setProjectSelection(2)}}
          className="project-highlight-image2 project-selection"
          style={projectSelection == 2 ? {opacity: 1} : {}}
        />
        <img
          alt="image"
          src="/photos/home/photo package/image3%20(1)-min%20(2).jpeg"
          onClick={() => {setAutoScroll(false);setProjectSelection(3)}}
          className="project-highlight-image3 project-selection"
          style={projectSelection == 3 ? {opacity: 1} : {}}
        />
        <img
          alt="image"
          src="/photos/home/photo package/337428342_553691676601498_7416457620280275304_n-1000w.jpg"
          onClick={() => {setAutoScroll(false);setProjectSelection(4)}}
          className="project-highlight-image4 project-selection"
          style={projectSelection == 4 ? {opacity: 1} : {}}
        />
      </div>
    </div>
  )
}

export default ProjectHighlight
