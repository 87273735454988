import React, {useState} from 'react'

import PropTypes from 'prop-types'

import ContactPopup from './contact-popup'
import './contact-form.css'

const ContactForm = (props) => {
  let hook = {
    run: ()=>{}
  }

  const handleSubmit = async (event) => {
    //prevent changing url
    event.preventDefault();

    // Get form data
    const formData = new FormData(event.target);

    // Convert to a more readable format if needed, like an object
    const data = {
      name: formData.get('name'),
      phone: formData.get('phone'),
      email: formData.get('email'),
      message: formData.get('message'),
    };

    //send request to server
    const response = await fetch(window.location.protocol+'//'+window.location.host+'/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    //run hook to set popup to visible
    if(response.ok){
      console.log('set visible')
      hook.run();
    }

  }


  return (
    <div
      id={props.contactFormId}
      className={`contact-form-contact-form ${props.rootClassName} `}
    >
      <div className="contact-form-max-width1">
        <div className="contact-form-heading-container">
          <span className="contact-form-prehead Prehead">Contact us</span>
          <h1 className="contact-form-text10 SectionHeading">
            <span className="SectionHeading contact-form-text11">
              Your dream home awaits
            </span>
            <br></br>
          </h1>
          <span className="contact-form-text13 Content">
            <span>Have a remodeling idea in mind? </span>
            <span>Share the details with us,</span>
            <br></br>
            <span>we’ll help you turn your dream into reality.</span>
          </span>
          <span className="contact-form-text18 Content">
            <span>Have a remodeling idea in mind? </span>
            <span>Share the details with us, </span>
            <span>we’ll help you turn your dream into reality.</span>
          </span>
        </div>
        <div className="contact-form-max-width2">
          <form
            action="contact"
            method="POST"
            enctype="application/x-www-form-urlencoded"
            className="contact-form-form"
            onSubmit={handleSubmit}
          >
            <div className="contact-form-container1">
              <img
                alt="image"
                src="/icons/contact/user1-200h.png"
                className="contact-form-image1"
              />
              <div className="contact-form-input1 insetInputField">
                <input
                  type="text"
                  id="contact-form-8-first-name"
                  name="name"
                  required="true"
                  placeholder="Name"
                  className="contact-form-text-input1 Content"
                />
              </div>
            </div>
            <div className="contact-form-container2">
              <img
                alt="image"
                src="/icons/contact/envelope1-200h.png"
                className="contact-form-image2"
              />
              <div className="contact-form-input2 insetInputField">
                <input
                  type="text"
                  id="contact-form-8-email"
                  name="email"
                  required="true"
                  placeholder="Email"
                  className="contact-form-text-input2 Content"
                />
              </div>
            </div>
            <div className="contact-form-container3">
              <img
                alt="image"
                src="/icons/contact/phone-flip1-200h.png"
                className="contact-form-image3"
              />
              <div className="contact-form-input3 insetInputField">
                <input
                  type="text"
                  id="contact-form-8-phone"
                  name="phone"
                  required="true"
                  placeholder="Phone Number"
                  className="contact-form-text-input3 Content"
                />
              </div>
            </div>
            <div className="contact-form-container4">
              <img
                alt="image"
                src="/icons/contact/messages1-200h.png"
                className="contact-form-image4"
              />
              <div className="contact-form-input4 insetInputField">
                <textarea
                  id="contact-form-8-message"
                  name="message"
                  rows="4"
                  placeholder="Enter your message"
                  className="contact-form-textarea Content"
                ></textarea>
              </div>
            </div>
            <div className="contact-form-container5">
              <button
                type="submit"
                className="contact-form-button Content bluebutton"
              >
                <span>
                  <span>Submit</span>
                  <br></br>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <ContactPopup rootClassName="contact-popuproot-class-name2" popupHook={hook}></ContactPopup>
    </div>
  )
}

ContactForm.defaultProps = {
  contactFormId: '',
  rootClassName: '',
}

ContactForm.propTypes = {
  contactFormId: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default ContactForm
