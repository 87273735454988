import React, { Fragment } from 'react'
import Wrapper from '../components/page-wrapper'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import ContactForm from '../components/contact-form'
import Footer from '../components/footer'
import './home-test.css'

const HomeTest = (props) => {
  return (
    <Wrapper>
    <div className="home-test-container10">
      <Helmet>
        <title>HomeTest - Craft & Forge - Premium Home Remodeling</title>
        <meta
          name="description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:title"
          content="HomeTest - Craft &amp; Forge - Premium Home Remodeling"
        />
        <meta
          property="og:description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/133b66a8-437a-4fe1-8938-2c01fd5c558d/c7ed6f96-1e17-4876-a525-ef1747a6b890?org_if_sml=1&amp;force_format=original"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Navbar rootClassName="navbarroot-class-name1"></Navbar>
      <div className="home-test-hero-fade-center">
        <div className="home-test-hero">
          <div className="home-test-content-container">
            <animate-on-reveal
              animation="fadeInUp"
              duration="1000ms"
              delay="0s"
              direction="normal"
              easing="ease-out"
              iteration="1"
            >
              <div
                data-thq-animate-on-reveal="true"
                className="home-test-max-width1"
              >
                <div className="home-test-heading-container1">
                  <span className="home-test-prehead1">Craft &amp; Forge</span>
                  <span className="home-test-prehead2">
                    Craft &amp; Forge, previously Northwest unlimited
                  </span>
                  <span className="home-test-main-title Title">
                    <span>crafting homes,</span>
                    <br></br>
                    <span>forging futures</span>
                  </span>
                  <span className="home-test-text103">
                    <span>
                      At Craft &amp; Forge Homes, we build homes and lasting
                      relationships. Every project is
                    </span>
                    <br></br>
                    <span>
                      a partnership built on trust, where thoughtful design and
                      meticulous craftsmanship
                    </span>
                    <br></br>
                    <span>
                      {' '}
                      bring your vision to life, creating spaces and connections
                      that last a lifetime.
                    </span>
                  </span>
                  <span className="home-test-text109">
                    At Craft &amp; Forge Homes, we build homes and lasting
                    relationships. Every project is a partnership built on
                    trust, where thoughtful design and meticulous craftsmanship
                    bring your vision to life, creating spaces and connections
                    that last a lifetime.
                  </span>
                  <a
                    href="#Contact-form"
                    className="home-test-link bluebutton Content"
                  >
                    <span>Bring your vision to life</span>
                  </a>
                </div>
              </div>
            </animate-on-reveal>
          </div>
        </div>
      </div>
      <div className="home-test-bathroom-value">
        <div className="home-test-photo-container1">
          <div className="home-test-max-width2">
            <div className="home-test-column1">
              <div className="home-test-heading-container2">
                <h1 className="home-test-text111 SectionHeading">
                  <span>your </span>
                  <span>sanctuary</span>
                  <br></br>
                  <span> of luxury</span>
                </h1>
                <span className="home-test-text116 Content">
                  We design bathrooms that offer more than just beauty—they’re a
                  reflection of your lifestyle. Explore how our expert
                  craftsmanship can transform your bathroom into a sleek,
                  tranquil space.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-test-kitchen-value">
        <div className="home-test-photo-container2">
          <div className="home-test-max-width3">
            <div className="home-test-column2">
              <div className="home-test-heading-container3">
                <h1 className="home-test-text117 SectionHeading">
                  <span>
                    Cook in style,
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <span>live in comfort</span>
                </h1>
                <span className="home-test-text121 Content">
                  Whether you&apos;re entertaining guests or enjoying a quiet
                  family meal, your kitchen should be as refined as your taste.
                  Let Craft &amp; Forge elevate your cooking experience with
                  bold, intelligent designs.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-test-remodel-types">
        <animate-on-reveal
          animation="fadeIn"
          duration="1000ms"
          delay="0s"
          direction="normal"
          easing="ease-out"
          iteration="1"
        >
          <div
            data-thq-animate-on-reveal="true"
            className="home-test-heading-container4"
          >
            <span className="home-test-prehead3 Prehead">Our services</span>
            <h1 className="home-test-text122 SectionHeading">
              <span>
                transforming
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>every space</span>
            </h1>
          </div>
        </animate-on-reveal>
        <div className="home-test-types">
          <div className="home-test-bathrooms">
            <img
              alt="image"
              src="/photos/home/services/225749094_1025523698256360_6876364482089526948_n_edited.jpg"
              className="home-test-image10"
            />
            <div className="home-test-container11">
              <span className="home-test-text126">Bathrooms</span>
              <div className="home-test-container12"></div>
            </div>
          </div>
          <div className="home-test-kitchens">
            <img
              alt="image"
              src="/photos/home/services/image2-min-500w.jpeg"
              className="home-test-image11"
            />
            <div className="home-test-container13">
              <span className="home-test-text127">kitchens</span>
              <div className="home-test-container14"></div>
            </div>
          </div>
          <div className="home-test-renovations">
            <img
              alt="image"
              src="/photos/home/services/railing%203-500w.png"
              className="home-test-image12"
            />
            <div className="home-test-container15">
              <span className="home-test-text128">
                <span>
                  renovations
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>&amp; Additions</span>
              </span>
              <div className="home-test-container16"></div>
            </div>
          </div>
          <div className="home-test-additions">
            <img
              alt="image"
              src="/photos/home/services/img_3627-500w.jpeg"
              className="home-test-image13"
            />
            <div className="home-test-container17">
              <span className="home-test-text132">
                <span>custom</span>
                <br></br>
                <span>homes</span>
              </span>
              <div className="home-test-container18"></div>
            </div>
          </div>
        </div>
        <div className="home-test-bg1"></div>
      </div>
      <div className="home-test-project-highlight">
        <div className="home-test-bg2"></div>
        <animate-on-reveal
          animation="fadeIn"
          duration="1000ms"
          delay="0s"
          direction="normal"
          easing="ease-out"
          iteration="1"
        >
          <div
            data-thq-animate-on-reveal="true"
            className="home-test-heading-container5"
          >
            <span className="home-test-prehead4 Prehead">Our Work</span>
            <h1 className="home-test-text136 SectionHeading">
              <span>elevated designs,</span>
              <br></br>
              <span>showcased</span>
            </h1>
          </div>
        </animate-on-reveal>
        <div className="home-test-project0">
          <div className="home-test-photo">
            <img
              alt="image"
              src="/photos/home/photo package/162040081_463948628084164_9141381592977607458_n.jpg"
              className="home-test-image14"
            />
            <div className="home-test-container19"></div>
          </div>
          <div className="home-test-details">
            <span className="home-test-head Subheading">
              sleek and functional kitchen overhaul
            </span>
            <span className="home-test-location">
              <span className="Content">
                Location:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-test-text141">Bothell WA</span>
            </span>
            <span className="home-test-description">
              Featuring crisp white cabinetry and high-end stainless steel
              appliances, this kitchen exudes modern sophistication. The
              geometric backsplash adds subtle texture, while the clean lines
              and black accents make a bold statement in this elegantly
              reimagined space.
            </span>
            <div className="home-test-container20"></div>
          </div>
        </div>
        <div className="home-test-project-selection">
          <img
            alt="image"
            src="/photos/home/photo package/162040081_463948628084164_9141381592977607458_n.jpg"
            className="home-test-image0"
          />
          <img
            alt="image"
            src="/photos/home/photo package/234775954_869307217030980_5059897748366147267_n.jpg"
            className="home-test-image15"
          />
          <img
            alt="image"
            src="49fb6c96-e561-4aed-9147-1a9227081df3"
            className="home-test-image2"
          />
          <img
            alt="image"
            src="/photos/home/photo package/image3%20(1)-min%20(2).jpeg"
            className="home-test-image3"
          />
          <img
            alt="image"
            src="8ba0697e-2926-4444-af5a-9d64ac29f1ed"
            className="home-test-image4"
          />
        </div>
      </div>
      <div className="home-test-client-process-bubbles1">
        <div className="home-test-bg3">
          <div className="home-test-max-width4">
            <div className="home-test-heading-container6">
              <span className="home-test-prehead5">Our process</span>
              <h1 className="home-test-text142 SectionHeading">
                From vision to reality
              </h1>
              <span className="home-test-text143">
                <span>
                  Our remodeling process blends creativity, expertise, and
                  detailed planning.
                </span>
                <br></br>
                <span>
                  We guide you through every phase, ensuring a beautifully
                  crafted space that exceeds expectations.
                </span>
              </span>
              <span className="home-test-text147">
                <span>
                  Our remodeling process blends creativity, expertise, and
                  detailed planning. 
                </span>
                <span>
                  We guide you through every phase, ensuring a beautifully
                  crafted space that exceeds expectations.
                </span>
              </span>
            </div>
            <div className="home-test-timeline-container1">
              <div className="home-test-step110">
                <div className="home-test-container21">
                  <div className="home-test-progress010">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-test-arrow10"
                    />
                    <div className="home-test-container22"></div>
                    <span className="home-test-text150 Subheading">
                      <span>Start</span>
                      <br></br>
                    </span>
                  </div>
                </div>
                <div className="home-test-container23">
                  <div className="home-test-container24">
                    <div className="home-test-container25">
                      <img
                        alt="image"
                        src="/icons/process/videoconference-200h.png"
                        className="home-test-image16"
                      />
                      <h3 className="home-test-text153 Subheading">
                        Initial Discovery Call
                      </h3>
                    </div>
                  </div>
                  <span className="home-test-text154">
                    <span className="Content">
                      We start with a virtual consultation to understand your
                      remodeling goals, budget, and timeline, ensuring a perfect
                      fit for your project.
                    </span>
                    <br className="Content"></br>
                    <br className="Content"></br>
                    <span className="Content">This step includes:</span>
                    <br className="Content"></br>
                    <span className="home-test-text160 Content">
                      Needs Assessment
                    </span>
                    <span className="home-test-text161 Content">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="Content">
                      - Understanding your goals to tailor our services.
                    </span>
                    <br className="Content"></br>
                    <span className="home-test-text164 Content">
                      Budget Discussion
                    </span>
                    <span className="Content">
                      {' '}
                      - Aligning expectations to avoid surprises.
                    </span>
                    <br className="Content"></br>
                    <span className="home-test-text167 Content">
                      Preliminary Ideas
                    </span>
                    <span className="Content">
                      {' '}
                      - Sharing initial concepts to inspire alignment.
                    </span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-test-container26">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-test-image17"
                    />
                  </div>
                  <div className="home-test-img310"></div>
                </div>
              </div>
              <div className="home-test-step111">
                <div className="home-test-container27">
                  <div className="home-test-progress011">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-test-arrow11"
                    />
                  </div>
                </div>
                <div className="home-test-container28">
                  <div className="home-test-container29">
                    <div className="home-test-container30">
                      <img
                        alt="image"
                        src="/icons/process/land-layer-location-200h.png"
                        className="home-test-image18"
                      />
                      <h3 className="home-test-text170 Subheading">
                        On-site assessment
                      </h3>
                    </div>
                  </div>
                  <span className="home-test-text171">
                    <span className="Content">
                      We conduct a detailed site assessment, taking precise
                      measurements and discussing material options to refine the
                      project scope.
                    </span>
                    <br className="Content"></br>
                    <br className="Content"></br>
                    <span className="Content">This step includes:</span>
                    <br className="Content"></br>
                    <span className="home-test-text177">Material Options</span>
                    <span>
                      {' '}
                      - Presenting tailored material recommendations.
                    </span>
                    <br className="Content"></br>
                    <span className="home-test-text180">Scope Refinement</span>
                    <span> - Clarifying the project scope and priorities.</span>
                    <br className="Content"></br>
                    <span className="home-test-text183">Timeline Estimate</span>
                    <span> - Providing a preliminary timeline.</span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-test-container31">
                    <img
                      alt="image"
                      src="/icons/process/arrow-right-200h.png"
                      className="home-test-image19"
                    />
                  </div>
                  <div className="home-test-container32">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-test-image20"
                    />
                  </div>
                  <div className="home-test-img311"></div>
                </div>
              </div>
              <div className="home-test-step112">
                <div className="home-test-container33">
                  <div className="home-test-progress012">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-test-arrow12"
                    />
                  </div>
                </div>
                <div className="home-test-container34">
                  <div className="home-test-container35">
                    <div className="home-test-container36">
                      <img
                        alt="image"
                        src="/icons/process/plan-strategy-200h.png"
                        className="home-test-image21"
                      />
                      <h3 className="home-test-text186 Subheading">
                        Tailored solutions presentation
                      </h3>
                    </div>
                  </div>
                  <span className="home-test-text187">
                    <span>
                      We breakdown costs and detail each proposal to avoid
                      surprises, helping you make an informed decision aligned
                      with your vision.
                    </span>
                    <br></br>
                    <br></br>
                    <span>This step includes:</span>
                    <br></br>
                    <span className="home-test-text193">Custom Proposals</span>
                    <span> - Offering options tailored to your needs.</span>
                    <br></br>
                    <span className="home-test-text196">Cost Breakdown</span>
                    <span> - Providing transparent pricing details.</span>
                    <br></br>
                    <span className="home-test-text199">Scope Flexibility</span>
                    <span> - Adapting proposals to your preferences.</span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-test-container37">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-test-image22"
                    />
                  </div>
                  <div className="home-test-img312"></div>
                </div>
              </div>
              <div className="home-test-step113">
                <div className="home-test-container38">
                  <div className="home-test-progress013">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-test-arrow13"
                    />
                  </div>
                </div>
                <div className="home-test-container39">
                  <div className="home-test-container40">
                    <div className="home-test-container41">
                      <img
                        alt="image"
                        src="/icons/process/to-do-200h.png"
                        className="home-test-image23"
                      />
                      <h3 className="home-test-text202 Subheading">
                        Strategic Project Planning 
                      </h3>
                    </div>
                  </div>
                  <span className="home-test-text203">
                    <span>
                      Finalizing project details, we coordinate with architects
                      and engineers, create a detailed timeline, and handle all
                      project logistics.
                    </span>
                    <br></br>
                    <br></br>
                    <span>This step includes:</span>
                    <br></br>
                    <span className="home-test-text209">
                      Comprehensive Timeline
                    </span>
                    <span> - Outlining each project phase.</span>
                    <br></br>
                    <span className="home-test-text212">Permitting</span>
                    <span> - Managing all permits and regulations.</span>
                    <br></br>
                    <span className="home-test-text215">Quality Materials</span>
                    <span> - Sourcing high-quality materials.</span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-test-container42">
                    <img
                      alt="image"
                      src="/icons/process/arrow-right-200h.png"
                      className="home-test-image24"
                    />
                  </div>
                  <div className="home-test-container43">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-test-image25"
                    />
                  </div>
                  <div className="home-test-img313"></div>
                </div>
              </div>
              <div className="home-test-step114">
                <div className="home-test-container44">
                  <div className="home-test-progress014">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-test-arrow14"
                    />
                  </div>
                </div>
                <div className="home-test-container45">
                  <div className="home-test-img314"></div>
                  <div className="home-test-container46">
                    <div className="home-test-container47">
                      <img
                        alt="image"
                        src="/icons/process/builder-200h.png"
                        className="home-test-image26"
                      />
                      <h3 className="home-test-text218 Subheading">
                        Execution Phase
                      </h3>
                    </div>
                  </div>
                  <span className="home-test-text219">
                    <span>
                      Our skilled craftsmen bring your vision to life with
                      precision and care, maintaining open communication and
                      upholding
                    </span>
                    <br></br>
                    <span>the highest quality standards.</span>
                    <br></br>
                    <br></br>
                    <span>This step includes:</span>
                    <br></br>
                    <span className="home-test-text227">Quality Control</span>
                    <span> - Maintaining high standards throughout.</span>
                    <br></br>
                    <span className="home-test-text230">Progress Updates</span>
                    <span> - Keeping you informed and involved.</span>
                    <br></br>
                    <span className="home-test-text233">
                      Constant Collaboration
                    </span>
                    <span> - Welcoming feedback at every stage.</span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-test-container48">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-test-image27"
                    />
                  </div>
                </div>
              </div>
              <div className="home-test-step115">
                <div className="home-test-container49">
                  <div className="home-test-progress015">
                    <div className="home-test-container50"></div>
                    <span className="home-test-text236 Subheading">
                      <span>Finish</span>
                      <br></br>
                    </span>
                  </div>
                </div>
                <div className="home-test-container51">
                  <div className="home-test-container52">
                    <div className="home-test-container53">
                      <img
                        alt="image"
                        src="/icons/process/memo-circle-check-200h.png"
                        className="home-test-image28"
                      />
                      <h3 className="home-test-text239 Subheading">
                        Final reveal &amp; review
                      </h3>
                    </div>
                  </div>
                  <span className="home-test-text240">
                    <span>
                      Upon completion, we conduct a final walkthrough to ensure
                      satisfaction, offer a comprehensive warranty, and provide
                      ongoing support.
                    </span>
                    <br></br>
                    <br></br>
                    <span>This step includes:</span>
                    <br></br>
                    <span className="home-test-text246">
                      Thorough Walkthrough
                    </span>
                    <span> - Ensuring your complete satisfaction.</span>
                    <br></br>
                    <span className="home-test-text249">
                      Warranty &amp; Support
                    </span>
                    <span> - Providing ongoing peace of mind.</span>
                    <br></br>
                    <span className="home-test-text252">Follow-Up</span>
                    <span>
                      {' '}
                      - Conducting check-ins to ensure lasting satisfaction.
                    </span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-test-container54">
                    <img
                      alt="image"
                      src="/icons/process/arrow-right-200h.png"
                      className="home-test-image29"
                    />
                  </div>
                  <div className="home-test-container55">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-test-image30"
                    />
                  </div>
                  <div className="home-test-img315"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-test-bg4"></div>
        <div className="home-test-bg5"></div>
      </div>
      <div className="home-test-client-process-bubbles2">
        <div className="home-test-bg6">
          <div className="home-test-max-width5">
            <div className="home-test-heading-container7">
              <span className="home-test-prehead6">Our process</span>
              <h1 className="home-test-text255 SectionHeading">
                From vision to reality
              </h1>
              <span className="home-test-text256">
                <span>
                  Our remodeling process blends creativity, expertise, and
                  detailed planning.
                </span>
                <br></br>
                <span>
                  We guide you through every phase, ensuring a beautifully
                  crafted space that exceeds expectations.
                </span>
              </span>
              <span className="home-test-text260">
                <span>
                  Our remodeling process blends creativity, expertise, and
                  detailed planning. 
                </span>
                <span>
                  We guide you through every phase, ensuring a beautifully
                  crafted space that exceeds expectations.
                </span>
              </span>
            </div>
            <div className="home-test-timeline-container2">
              <div className="home-test-step116">
                <div className="home-test-container56">
                  <div className="home-test-progress016">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-test-arrow15"
                    />
                    <div className="home-test-container57"></div>
                    <span className="home-test-text263 Subheading">
                      <span>Start</span>
                      <br></br>
                    </span>
                  </div>
                </div>
                <div className="home-test-container58">
                  <div className="home-test-container59">
                    <div className="home-test-container60">
                      <img
                        alt="image"
                        src="/icons/process/videoconference-200h.png"
                        className="home-test-image31"
                      />
                      <h3 className="home-test-text266 Subheading">
                        Initial Discovery Call
                      </h3>
                    </div>
                  </div>
                  <span className="home-test-text267">
                    <span className="Content">
                      We start with a virtual consultation to understand your
                      remodeling goals, budget, and timeline, ensuring a perfect
                      fit for your project.
                    </span>
                    <br className="Content"></br>
                    <br className="Content"></br>
                    <span className="Content">This step includes:</span>
                    <br className="Content"></br>
                    <span className="home-test-text273 Content">
                      Needs Assessment
                    </span>
                    <span className="home-test-text274 Content">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="Content">
                      - Understanding your goals to tailor our services.
                    </span>
                    <br className="Content"></br>
                    <span className="home-test-text277 Content">
                      Budget Discussion
                    </span>
                    <span className="Content">
                      {' '}
                      - Aligning expectations to avoid surprises.
                    </span>
                    <br className="Content"></br>
                    <span className="home-test-text280 Content">
                      Preliminary Ideas
                    </span>
                    <span className="Content">
                      {' '}
                      - Sharing initial concepts to inspire alignment.
                    </span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-test-container61">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-test-image32"
                    />
                  </div>
                  <div className="home-test-img316"></div>
                </div>
              </div>
              <div className="home-test-step117">
                <div className="home-test-container62">
                  <div className="home-test-progress017">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-test-arrow16"
                    />
                  </div>
                </div>
                <div className="home-test-container63">
                  <div className="home-test-container64">
                    <div className="home-test-container65">
                      <img
                        alt="image"
                        src="/icons/process/land-layer-location-200h.png"
                        className="home-test-image33"
                      />
                      <h3 className="home-test-text283 Subheading">
                        On-site assessment
                      </h3>
                    </div>
                  </div>
                  <span className="home-test-text284">
                    <span className="Content">
                      We conduct a detailed site assessment, taking precise
                      measurements and discussing material options to refine the
                      project scope.
                    </span>
                    <br className="Content"></br>
                    <br className="Content"></br>
                    <span className="Content">This step includes:</span>
                    <br className="Content"></br>
                    <span className="home-test-text290">Material Options</span>
                    <span>
                      {' '}
                      - Presenting tailored material recommendations.
                    </span>
                    <br className="Content"></br>
                    <span className="home-test-text293">Scope Refinement</span>
                    <span> - Clarifying the project scope and priorities.</span>
                    <br className="Content"></br>
                    <span className="home-test-text296">Timeline Estimate</span>
                    <span> - Providing a preliminary timeline.</span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-test-container66">
                    <img
                      alt="image"
                      src="/icons/process/arrow-right-200h.png"
                      className="home-test-image34"
                    />
                  </div>
                  <div className="home-test-container67">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-test-image35"
                    />
                  </div>
                  <div className="home-test-img317"></div>
                </div>
              </div>
              <div className="home-test-step118">
                <div className="home-test-container68">
                  <div className="home-test-progress018">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-test-arrow17"
                    />
                  </div>
                </div>
                <div className="home-test-container69">
                  <div className="home-test-container70">
                    <div className="home-test-container71">
                      <img
                        alt="image"
                        src="/icons/process/plan-strategy-200h.png"
                        className="home-test-image36"
                      />
                      <h3 className="home-test-text299 Subheading">
                        Tailored solutions presentation
                      </h3>
                    </div>
                  </div>
                  <span className="home-test-text300">
                    <span>
                      We breakdown costs and detail each proposal to avoid
                      surprises, helping you make an informed decision aligned
                      with your vision.
                    </span>
                    <br></br>
                    <br></br>
                    <span>This step includes:</span>
                    <br></br>
                    <span className="home-test-text306">Custom Proposals</span>
                    <span> - Offering options tailored to your needs.</span>
                    <br></br>
                    <span className="home-test-text309">Cost Breakdown</span>
                    <span> - Providing transparent pricing details.</span>
                    <br></br>
                    <span className="home-test-text312">Scope Flexibility</span>
                    <span> - Adapting proposals to your preferences.</span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-test-container72">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-test-image37"
                    />
                  </div>
                  <div className="home-test-img318"></div>
                </div>
              </div>
              <div className="home-test-step119">
                <div className="home-test-container73">
                  <div className="home-test-progress019">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-test-arrow18"
                    />
                  </div>
                </div>
                <div className="home-test-container74">
                  <div className="home-test-container75">
                    <div className="home-test-container76">
                      <img
                        alt="image"
                        src="/icons/process/to-do-200h.png"
                        className="home-test-image38"
                      />
                      <h3 className="home-test-text315 Subheading">
                        Strategic Project Planning 
                      </h3>
                    </div>
                  </div>
                  <span className="home-test-text316">
                    <span>
                      Finalizing project details, we coordinate with architects
                      and engineers, create a detailed timeline, and handle all
                      project logistics.
                    </span>
                    <br></br>
                    <br></br>
                    <span>This step includes:</span>
                    <br></br>
                    <span className="home-test-text322">
                      Comprehensive Timeline
                    </span>
                    <span> - Outlining each project phase.</span>
                    <br></br>
                    <span className="home-test-text325">Permitting</span>
                    <span> - Managing all permits and regulations.</span>
                    <br></br>
                    <span className="home-test-text328">Quality Materials</span>
                    <span> - Sourcing high-quality materials.</span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-test-container77">
                    <img
                      alt="image"
                      src="/icons/process/arrow-right-200h.png"
                      className="home-test-image39"
                    />
                  </div>
                  <div className="home-test-container78">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-test-image40"
                    />
                  </div>
                  <div className="home-test-img319"></div>
                </div>
              </div>
              <div className="home-test-step120">
                <div className="home-test-container79">
                  <div className="home-test-progress020">
                    <img
                      alt="image"
                      src="/icons/process/arrow-down-300h.png"
                      className="home-test-arrow19"
                    />
                  </div>
                </div>
                <div className="home-test-container80">
                  <div className="home-test-img320"></div>
                  <div className="home-test-container81">
                    <div className="home-test-container82">
                      <img
                        alt="image"
                        src="/icons/process/builder-200h.png"
                        className="home-test-image41"
                      />
                      <h3 className="home-test-text331 Subheading">
                        Execution Phase
                      </h3>
                    </div>
                  </div>
                  <span className="home-test-text332">
                    <span>
                      Our skilled craftsmen bring your vision to life with
                      precision and care, maintaining open communication and
                      upholding
                    </span>
                    <br></br>
                    <span>the highest quality standards.</span>
                    <br></br>
                    <br></br>
                    <span>This step includes:</span>
                    <br></br>
                    <span className="home-test-text340">Quality Control</span>
                    <span> - Maintaining high standards throughout.</span>
                    <br></br>
                    <span className="home-test-text343">Progress Updates</span>
                    <span> - Keeping you informed and involved.</span>
                    <br></br>
                    <span className="home-test-text346">
                      Constant Collaboration
                    </span>
                    <span> - Welcoming feedback at every stage.</span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-test-container83">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-test-image42"
                    />
                  </div>
                </div>
              </div>
              <div className="home-test-step121">
                <div className="home-test-container84">
                  <div className="home-test-progress021">
                    <div className="home-test-container85"></div>
                    <span className="home-test-text349 Subheading">
                      <span>Finish</span>
                      <br></br>
                    </span>
                  </div>
                </div>
                <div className="home-test-container86">
                  <div className="home-test-container87">
                    <div className="home-test-container88">
                      <img
                        alt="image"
                        src="/icons/process/memo-circle-check-200h.png"
                        className="home-test-image43"
                      />
                      <h3 className="home-test-text352 Subheading">
                        Final reveal &amp; review
                      </h3>
                    </div>
                  </div>
                  <span className="home-test-text353">
                    <span>
                      Upon completion, we conduct a final walkthrough to ensure
                      satisfaction, offer a comprehensive warranty, and provide
                      ongoing support.
                    </span>
                    <br></br>
                    <br></br>
                    <span>This step includes:</span>
                    <br></br>
                    <span className="home-test-text359">
                      Thorough Walkthrough
                    </span>
                    <span> - Ensuring your complete satisfaction.</span>
                    <br></br>
                    <span className="home-test-text362">
                      Warranty &amp; Support
                    </span>
                    <span> - Providing ongoing peace of mind.</span>
                    <br></br>
                    <span className="home-test-text365">Follow-Up</span>
                    <span>
                      {' '}
                      - Conducting check-ins to ensure lasting satisfaction.
                    </span>
                    <br className="ContentSmall"></br>
                  </span>
                  <div className="home-test-container89">
                    <img
                      alt="image"
                      src="/icons/process/arrow-right-200h.png"
                      className="home-test-image44"
                    />
                  </div>
                  <div className="home-test-container90">
                    <img
                      alt="image"
                      src="/icons/process/arrow-left-200h.png"
                      className="home-test-image45"
                    />
                  </div>
                  <div className="home-test-img321"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-test-bg7"></div>
      </div>
      <div className="home-test-meet-the-founder-page-cut">
        <div className="home-test-gradient-container1"></div>
        <div className="home-test-blur-container">
          <div className="home-test-max-width6">
            <div className="home-test-container91">
              <div className="home-test-heading-container8">
                <span className="home-test-prehead7">Our founder</span>
                <h1 className="home-test-text368 SectionHeading">
                  <span>Crafting a Legacy</span>
                  <br></br>
                  <span>of excellence</span>
                </h1>
                <h1 className="home-test-text372 SectionHeading">
                  <span>
                    Crafting a Legacy
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>of excellence</span>
                </h1>
              </div>
              <div className="home-test-content">
                <div className="home-test-line"></div>
                <div className="home-test-section-title">
                  <h2 className="home-test-text375">
                    <span className="Subheading">Dayne Richards</span>
                    <br></br>
                  </h2>
                  <p className="home-test-text378 Content">
                    <span>
                      With over 20 years of experience in the remodeling
                      industry, Dayne founded Craft &amp; Forge to bring a new
                      level of excellence to home renovation. His dedication to
                      modern design and meticulous craftsmanship has transformed
                      countless homes into architectural masterpieces.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      &quot;For me, home has always been a place where my values
                      need to be represented. Having a beautiful home could be
                      chalked up to vanity, but it so much more than that. We
                      spend so much time, energy, and money on our homes. Why
                      shouldn’t it be something that truly reflects us and is a
                      place we are proud of.&quot;
                    </span>
                    <br></br>
                  </p>
                </div>
              </div>
            </div>
            <img
              alt="Craft &amp; Forge Features"
              src="/photos/home/dayne%20head%20mixed2-1000w.png"
              className="home-test-image110"
            />
          </div>
        </div>
        <div className="home-test-gradient-container2"></div>
      </div>
      <ContactForm rootClassName="contact-formroot-class-name"></ContactForm>
      <Footer
        link11={
          <Fragment>
            <span className="home-test-text384">Full Floor Remodels</span>
          </Fragment>
        }
        text10={
          <Fragment>
            <span className="home-test-text385">
              Follow us on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        text14={
          <Fragment>
            <span className="home-test-text386">
              <span className="home-test-text387">Home</span>
              <br></br>
            </span>
          </Fragment>
        }
        text24={
          <Fragment>
            <span className="home-test-text389">About</span>
          </Fragment>
        }
        text34={
          <Fragment>
            <span className="home-test-text390">Contact</span>
          </Fragment>
        }
        text91={
          <Fragment>
            <span className="home-test-text391">
              <span>Custom Home Builds</span>
              <br></br>
            </span>
          </Fragment>
        }
        text111={
          <Fragment>
            <span className="home-test-text394">Kitchen Remodels</span>
          </Fragment>
        }
        text211={
          <Fragment>
            <span className="home-test-text395">Bathroom Remodels</span>
          </Fragment>
        }
        text311={
          <Fragment>
            <span className="home-test-text396">
              <span className="home-test-text397">House Additions</span>
              <br></br>
            </span>
          </Fragment>
        }
        footerId="Footer"
        imageSrc="/logos/logo%20dark-200h.png"
        text1311={
          <Fragment>
            <span className="home-test-text399">
              ©Craft and Forge 2024 All rights reserved
            </span>
          </Fragment>
        }
      ></Footer>
    </div>
    </Wrapper>
  )
}

export default HomeTest
